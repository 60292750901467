import React, { useEffect } from "react";
import Qr from "../_utils/Qr";
import Database from "../_utils/Database";
import { goto } from "../_utils/Misc";

import moment from 'moment'
import jwtDecode from 'jwt-decode';

const Index = () => {
    let userData = !!Database.getUserToken() && jwtDecode(Database.getUserToken());
    useEffect(() => {
        // Check if token has expired
        if (!!userData && userData.exp < moment(new Date()).unix()) {
            Database.removeUserToken();
        }

        checkRedirection();
    }, []);

    const checkRedirection = () => {
        // Check for QR parameters
        if (Qr.hasQueryParams()) {

            // Save query data & redirect to reward page
            Qr.storeParams();

            // Check where to go
            // if (Qr.getQueryParams().accessToken) {
            //     // Login
            //     // goto("/login-fetch/");
            // } else
            if (Database.getUserToken()) {
                goto("/qr-rewards-fetch/");
            } else {
                goto("/reward-lander/");
            }
        } else {
            // Redirect to home page
            goto("/home/");
        }
    };

    return <></>;
};

export default Index;
